<template>
  <div class="_wrap">
    <div class="_banner">
      <!-- <img src="@/assets/image/industries-img/wenchuang_banner.png" class="_bannerImg" alt="八路军驻洛办事处纪念馆-官方网站"> -->
      <img :src="$IMG_URL + $store.state.webData.top_image.cultural_creativity_list_image" alt="八路军驻洛办事处纪念馆-官方网站">

      <!-- <img src="@/assets/image/industries-img/wen chuang_bg_text.png" class="_text_img" alt="八路军驻洛办事处纪念馆-官方网站"> -->
    </div>

    <div class="_auto">
      <div class="_list">
        <div class="_li" v-for="(item, index) in list" :key="index">
          <div class="_cont">
            <div class="_img">

              <img :src="$IMG_URL + item.cover" alt="八路军驻洛办事处纪念馆-官方网站">

            </div>
            <h4>{{ item.title }}</h4>
          </div>

        </div>



      </div>

      <div class="_page">
        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="queryParams.page_num"
          @pagination="getList" />

      </div>

    </div>


    <mainFooter ref="mainFooterRef"></mainFooter>
  </div>
</template>

<script>
import { culturalCreativity } from "@/api/getData"
export default {
  data() {
    return {
      wbTitle: "八路军驻洛办事处纪念馆-文创",
      keywords: "八路军驻洛办事处纪念馆,洛八办",
      desc: "八路军驻洛办事处纪念馆",
      total: 0,
      queryParams: {
        page: 1,
        page_num: 10
      },
      list: [],

    }
  },
  // 动态设置页面标题、关键字和描述等属性  
  metaInfo() {

    return {
      title: this.wbTitle,
      // meta: [
      //   { name: "keywords", content: this.keywords },
      //   { name: "description", content: this.desc },
      // ]
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // 平滑滚动
    })
  },
  methods: {
    getList() {
      culturalCreativity(this.queryParams).then(res => {
        console.log(res)
        this.total = res.data.total
        this.list = res.data.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>

@import "../../common/mixin.scss";

._wrap
{
  width: 100%;
  overflow: hidden;
  background-color: #FDFAF6;
}

._auto
{
  width: 75%;
  margin: 0 auto;
}

._banner
{
  width: 100%;
  height: 700px;
  position: relative;

  ._bannerImg
  {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ._text_img
  {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -97px;
  }


}

._list
{
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;

  ._li:hover ._cont h4
  {
    color: $hover-color;
  }

  ._li
  {
    width: 31.3%;
    cursor: pointer;
    height: 364px;
    margin-right: 3%;
    margin-bottom: 40px;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #E9E9E9;

    // padding: 22px;
    ._cont
    {
      padding: 22px;

      ._img
      {
        width: 100%;

        height: 262px;
        margin: 0 auto;
        overflow: hidden;

      }

      img
      {
        width: 100%;

        height: 100%;
        object-fit: cover;
        transition: all .5s;
      }

      img:hover
      {
        transform: scale(1.1);
      }

      h4
      {
        color: #333;
        font-size: 20px;
        text-align: center;
        margin-top: 21px;
      }
    }

    &:nth-child(3n)
    {
      margin-right: 0;
    }
  }


}

._page
{
  text-align: center;
  margin-bottom: 68px;
  margin-top: -20px;
}


@include respondTo('phone'){

  ._banner{
    height: 300px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  ._auto{
    padding: 0 8px;
  }

  ._list{
    margin-top: 20px;


    ._li{
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  ._page{
    margin-top: 0;
    margin-bottom: 30px;
  }
}

</style>